<template>
  <div class="calendar" :style="`min-width: ${7 * dateItemWidth}px;`">
    <div class="font-align-center">
      <div class="btn-title padding-vertical-small padding-horizontal layout-horizontal layout-middle"
           @click="handleTitleClick">
        <div>{{ title }}</div>
        <div class="title-icon">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
    </div>
    <div
        class="calendar-container"
        :style="`height: ${containerHeight}px;`"
    >
      <canvas
          ref="canvas"
          class="canvas-calendar"
          :style="`width: ${7 * dateItemWidth}px; height: ${titleHeight + 6 * dateItemHeight}`"
          style="cursor: pointer"
          @mousedown="handleMouseDown"
          @mouseup="handleMouseUp"
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
      >
      </canvas>
    </div>
    <div class="layout-vertical layout-center padding-top-small">
      <div class="btn-expend" @click="handleExpendClick">
        <i class="fas fa-angle-down" v-if="isSimple"></i>
        <i class="fas fa-angle-up" v-else></i>
      </div>
    </div>
  </div>
</template>

<script>
import {TimeUtils} from "@/assets/javascript/time-utils";

const weeks = ['日', '一', '二', '三', '四', '五', '六'];

const Mode = {
  MODE_DATE: 0,
  MODE_MONTH: 1,
  MODE_YEAR: 2,
}

export default {
  name: "Calendar",
  props: {
    date: String,

    start: String,
    end: String,

    dateMarkDefinitions: {
      type: Array,
      default() {
        return [
          {
            backgroundColor: '#faf4f5',
          }
        ];
      }
    },

    dateMarks: {
      type: Object,
      default() {
        return {
          "2021-11-11": 0,
          "2021-11-10": 0,
          "2021-11-09": 0,
        };
      }
    }
  },
  data() {
    return {

      //日历开始参考日期
      originalDate: new Date(),

      //偏移量
      offset: 0,
      minOffset: null,
      maxOffset: null,
      scale: 1,

      //绘图上下文
      ctx: null,

      //绘图区尺寸
      width: 0,
      height: 0,

      //容器的尺寸
      containerHeight: 0,

      //标题尺寸
      titleHeight: 28,

      //日期尺寸
      dateItemWidth: 42,
      dateItemHeight: 42,

      //月份尺寸
      monthColumns: 4,
      monthRows: 4,
      monthItemWidth: 0,
      monthItemHeight: 0,

      //年份尺寸
      yearColumns: 4,
      yearRows: 4,
      yearItemWidth: 0,
      yearItemHeight: 0,

      //是否收起状态
      isSimple: false,

      //显示模式
      mode: Mode.MODE_DATE,

      //鼠标移动事件
      isMouseStarting: false,
      isMouseMoving: false,
      mouseStartX: 0,
      mouseStartY: 0,
      mouseStartOffset: 0,

      //选中日期
      activeDate: new Date(),

      //date range
      startDate: null,
      endDate: null,

      //当前日期
      hoverDate: null,
      currentMonth: new Date(),
      currentYear: new Date(),
      title: null,

      //主题样式
      activedColor: '#f091a6',
      primaryTextColor: '#606266',
      secondaryTextColor: '#bec0c0',

      //刷新
      requireRender: false,

      //动画
      animationInterval: 20,

      scrollTimerId: null,
      scrollDuration: 1000,

      simpleStateTimerId: null,
      simpleStateDuration: 1000,

      modeTimerId: null,
      modeDuration: 1000,
    }
  },

  computed: {
    calendarState: function () {
      const {dateItemWidth, dateItemHeight, titleHeight, monthColumns, monthRows, yearColumns, yearRows} = this;
      return {
        dateItemWidth,
        dateItemHeight,
        titleHeight,
        monthColumns,
        monthRows,
        yearColumns,
        yearRows,
      }
    },
    dateRange: function () {
      const {start, end} = this;
      return {
        start,
        end,
      }
    }
  },

  watch: {
    calendarState: {
      handler: function () {
        this.width = 7 * this.dateItemWidth;
        this.height = this.titleHeight + 6 * this.dateItemHeight;
        this.containerHeight = this.titleHeight + (this.isSimple ? 1 : 6) * this.dateItemHeight;
        this.monthItemWidth = this.width / this.monthColumns;
        this.monthItemHeight = this.height / this.monthRows;
        this.yearItemWidth = this.width / this.yearColumns;
        this.yearItemHeight = this.height / this.yearRows;
      },
      immediate: true,
    },
    date: {
      handler: function (date) {
        this.activeDate = date ? TimeUtils.parseDate(date) : new Date();
        this.scrollTo(this.activeDate);
      },
      immediate: true,
    },
    currentMonth: {
      handler: function (currentMonth) {
        this.currentYear = TimeUtils.getYearStart(currentMonth);
        if (this.mode === Mode.MODE_DATE) {
          this.title = TimeUtils.format('yyyy年MM月', this.currentMonth);
        }
      },
      immediate: true,
    },
    currentYear: {
      handler: function () {
        if (this.mode === Mode.MODE_MONTH) {
          this.title = TimeUtils.format('yyyy年', this.currentYear);
        }
      },
      immediate: true,
    },
    offset: {
      handler: function () {
        this.handleOffsetChange();
      },
      immediate: true,
    },
    dateRange: {
      handler: function () {
        this.startDate = this.start ? TimeUtils.getDayStart(this.start) : null;
        this.endDate = this.end ? TimeUtils.getDayEnd(this.end) : null;

        this.setMode(this.mode, false);
      },
      immediate: true,
    },
  },

  mounted() {
    this.$refs.canvas.addEventListener('mousewheel', this.handleMouseScroll);

    this.draw();
  },

  methods: {

    handleTitleClick: function () {
      if (this.isSimple) {
        this.setSimpleState(false);
      }
      if (this.mode === Mode.MODE_DATE) {
        this.setMode(Mode.MODE_MONTH);
      } else if (this.mode === Mode.MODE_MONTH) {
        this.setMode(Mode.MODE_YEAR);
      }

    },

    handleExpendClick: function () {
      this.setSimpleState(!this.isSimple);
    },

    handleMouseDown: function (e) {
      this.isMouseStarting = true;
      this.mouseStartX = e.x;
      this.mouseStartY = e.y;
    },

    handleMouseUp: function (e) {
      if (this.isMouseStarting) {
        let rect = this.$refs.canvas.getBoundingClientRect();
        let x = e.x - rect.left;
        let y = e.y - rect.top;
        switch (this.mode) {
          case Mode.MODE_DATE: {
            let date = this.getDateFromPoint(x, y);
            if (!this.isDateInvalid(date)) {
              this.activeDate = date;
              this.render();
              this.$emit('change', this.activeDate);
            }
            break;
          }
          case Mode.MODE_MONTH: {
            let currentMonth = TimeUtils.getMonthStart(this.getDateFromPoint(x, y));
            if (!this.isMonthInvalid(currentMonth)) {
              this.setMode(Mode.MODE_DATE);
              this.currentMonth = currentMonth;
              this.scrollTo(currentMonth, false);
              this.render();
            }
            break;
          }
          case Mode.MODE_YEAR: {
            let currentYear = TimeUtils.getYearStart(this.getDateFromPoint(x, y));
            if (this.isYearInvalid(currentYear)) break;
            let currentMonth = new Date(currentYear.getFullYear(), this.activeDate.getMonth(), this.activeDate.getDate());
            if (this.isMonthInvalid(currentMonth)) {
              if (this.startDate && currentMonth.getTime() < this.startDate.getTime()) {
                currentMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
              }
              if (this.endDate && currentMonth.getTime() > this.endDate.getTime()) {
                currentMonth = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
              }
            }
            this.setMode(Mode.MODE_MONTH);
            this.currentMonth = currentMonth;
            this.scrollTo(currentMonth, false);
            this.render();
            break;
          }
        }
      }

      this.isMouseStarting = false;
      this.isMouseMoving = false;
    },

    handleMouseLeave: function () {
      this.isMouseStarting = false;
      this.isMouseMoving = false;
      this.hoverDate = null;
      this.render();
    },

    handleMouseMove: function (e) {
      if (this.isMouseStarting) {
        if(Math.max(Math.abs(e.x - this.mouseStartX), Math.abs(e.y - this.mouseStartY)) > 5) {
          this.isMouseStarting = false;
          this.isMouseMoving = true;
          this.mouseStartY = e.y;
          this.mouseStartOffset = this.offset;
        }
      } else if (this.isMouseMoving) {
        if (!this.isSimple) {
          this.setOffset(this.mouseStartOffset + e.y - this.mouseStartY);
          this.render();
        }
      } else {
        let rect = this.$refs.canvas.getBoundingClientRect();
        let date = this.getDateFromPoint(e.x - rect.left, e.y - rect.top);
        this.hoverDate = date;
        this.render();
      }
    },

    handleMouseScroll: function (e) {
      if (!this.isSimple) {
        this.setOffset(this.offset - e.deltaY * .2);
        this.render();
      }
    },

    handleOffsetChange: function () {
      switch (this.mode) {
        case Mode.MODE_DATE: {
          let weekIndex = this.getWeekIndexByPoint(.5 * this.width, .5 * this.height);
          let currentMonth = TimeUtils.getMonthStart(this.getDateByIndex(weekIndex, 0));
          if (currentMonth.getTime() !== this.currentMonth.getTime()) {
            this.currentMonth = currentMonth;
            this.$emit('changecurrent', {month: TimeUtils.format('yyyy-MM-dd', currentMonth)})
          }
          break;
        }
        case Mode.MODE_MONTH: {
          let monthLineIndex = this.getMonthLineIndexByPoint(.5 * this.width, 0) + 1;
          let currentYear = TimeUtils.getYearStart(this.getMonthByIndex(monthLineIndex, 0));
          this.currentYear = currentYear;
          break;
        }
      }
    },

    setOffset: function (offset) {
      if (this.minOffset != null && offset < this.minOffset) {
        offset = this.minOffset;
      }
      if (this.maxOffset != null && offset > this.maxOffset) {
        offset = this.maxOffset;
      }

      this.offset = offset;
    },

    /**
     * 滚动到指定日期
     */
    scrollTo: function (date, animated = true) {

      if (this.scrollTimerId) {
        clearInterval(this.scrollTimerId);
        this.scrollTimerId = null;
      }

      this.hoverDate = null;

      let targetOffset;
      switch (this.mode) {
        case Mode.MODE_DATE: {
          let weekIndex;
          if (this.isSimple) {
            weekIndex = this.getWeekIndexByDate(date);
          } else {
            weekIndex = this.getWeekIndexByDate(TimeUtils.getMonthStart(date));
          }
          targetOffset = -this.dateItemHeight * weekIndex;
          break;
        }
        case Mode.MODE_MONTH: {
          let monthLineIndex;
          if (this.isSimple) {
            monthLineIndex = this.getMonthLineIndexByDate(date);
          } else {
            monthLineIndex = this.getMonthLineIndexByDate(TimeUtils.getYearStart(date));
          }
          targetOffset = -this.monthItemHeight * monthLineIndex;
          break;
        }
        case Mode.MODE_YEAR: {
          let yearLineIndex;
          if (this.isSimple) {
            yearLineIndex = this.getYearLineIndexByDate(date);
          } else {
            yearLineIndex = this.getYearLineIndexByDate(date) - 1;
          }
          targetOffset = -this.yearItemHeight * yearLineIndex;
          break;
        }
      }

      if (animated) {
        let steps = this.scrollDuration / this.animationInterval;
        let deltaOffset = (targetOffset - this.offset) / steps;
        this.scrollTimerId = setInterval(() => {
          let offset = this.offset + deltaOffset;
          let isFinished = false;
          if (Math.abs(offset - targetOffset) <= Math.abs(deltaOffset)) {
            offset = targetOffset;
            isFinished = true;
          }
          this.setOffset(offset);
          this.render();
          if (isFinished) {
            clearInterval(this.scrollTimerId);
            this.scrollTimerId = null;
          }
        }, 0, this.animationInterval);
      } else {
        this.setOffset(targetOffset);
        this.render();
      }
    },

    setSimpleState: function (isSimple, animated = true) {
      if (this.simpleStateTimerId) {
        clearInterval(this.simpleStateTimerId);
        this.simpleStateTimerId = null;
      }

      this.isSimple = isSimple;

      let targetHeight = this.titleHeight + (isSimple ? 1 : 6) * this.dateItemHeight;

      this.setMode(this.mode, false);
      this.scrollTo(this.activeDate);

      if (animated) {
        let steps = this.simpleStateDuration / this.animationInterval;
        let deltaHeight = (targetHeight - this.containerHeight) / steps;
        this.simpleStateTimerId = setInterval(() => {
          let isFinished = false;
          let containerHeight = this.containerHeight + deltaHeight;
          if (Math.abs(containerHeight - targetHeight) <= Math.abs(deltaHeight)) {
            containerHeight = targetHeight;
            isFinished = true;
          }
          this.containerHeight = containerHeight;
          if (isFinished) {
            clearInterval(this.simpleStateTimerId);
            this.simpleStateTimerId = null;
          }
          this.render();
        }, 0, this.animationInterval);
      } else {
        this.containerHeight = targetHeight;
        this.render();
      }
    },

    setMode: function (mode, animated = true) {
      if (this.modeTimerId) {
        clearInterval(this.modeTimerId);
        this.modeTimerId = null;
      }

      this.mode = mode;

      let minOffset = null;
      let maxOffset = null;
      if (!this.isSimple) {
        switch (this.mode) {
          case Mode.MODE_DATE: {
            if (this.start) {
              let minWeekIndex = this.getWeekIndexByDate(this.start) - 1;
              maxOffset = -minWeekIndex * this.dateItemHeight;
            }
            if (this.end) {
              let maxWeekIndex = this.getWeekIndexByDate(this.end) - 4;
              minOffset = -maxWeekIndex * this.dateItemHeight;
            }
            break;
          }
          case Mode.MODE_MONTH: {
            if (this.start) {
              let minMonthIndex = this.getMonthLineIndexByDate(this.start) - 1;
              maxOffset = -minMonthIndex * this.monthItemHeight;
            }
            if (this.end) {
              let maxMonthIndex = this.getMonthLineIndexByDate(this.end) - this.monthRows + 1;
              minOffset = -maxMonthIndex * this.monthItemHeight;
            }
            break;
          }
          case Mode.MODE_YEAR: {
            if (this.start) {
              let minYearIndex = this.getYearLineIndexByDate(this.start) - 1;
              maxOffset = -minYearIndex * this.yearItemHeight;
            }
            if (this.end) {
              let maxYearIndex = this.getYearLineIndexByDate(this.end) - this.yearRows + 1;
              minOffset = -maxYearIndex * this.yearItemHeight;
            }
            break;
          }
        }
      }

      this.minOffset = minOffset;
      this.maxOffset = maxOffset;

      this.scrollTo(this.currentMonth);

      if (animated) {
        let sourceScale = 1.5;
        let steps = this.modeDuration / this.animationInterval;
        let scaleDelta = (1.0 - sourceScale) / steps;
        this.scale = sourceScale;
        this.render();
        this.modeTimerId = setInterval(() => {
          let isFinished = false;
          let scale = this.scale + scaleDelta;
          if (scale <= 1.0) {
            scale = 1.0;
            isFinished = true;
          }

          if (isFinished) {
            clearInterval(this.modeTimerId);
            this.modeTimerId = null;
          }
          this.scale = scale;
          this.render();
        }, 0, this.animationInterval);
      }

    },

    draw() {
      let canvas = this.$refs.canvas;
      let scale = 4;
      canvas.width = this.dateItemWidth * 7 * scale;
      canvas.height = (this.dateItemHeight * 6 + this.titleHeight) * scale;
      let ctx = canvas.getContext("2d");
      ctx.scale(scale, scale);
      this.ctx = ctx;
      this.render();
    },

    transformX: function (x) {
      if (this.scale == 1.0) {
        return x;
      } else {
        return (x - .5 * this.width) * this.scale + +.5 * this.width;
      }
    },

    transformY: function (y) {
      if (this.scale == 1.0) {
        return y + this.offset;
      } else {
        let centerY = .5 * this.height - this.offset;
        return (y - centerY) * this.scale + centerY + this.offset;
      }
    },

    transformSize: function (size) {
      return size * this.scale;
    },

    getGlobalAlpha: function () {
      let alpha = 1 / this.scale;
      return alpha > 1 ? 1 : alpha;
    },

    getWeekIndexByDate: function (date) {
      return TimeUtils.weeksBetween(this.originalDate, date);
    },

    getWeekIndexByPoint: function (x, y) {
      return Math.floor((y - this.offset - this.titleHeight) / this.dateItemHeight);
    },

    getDayIndexInLineByPoint: function (x, y) {
      return Math.floor(x / this.dateItemWidth);
    },

    getDateByIndex(weekIndex, dayIndexInLine) {
      return new Date(TimeUtils.getWeekStart(this.originalDate).getTime() + (7 * weekIndex + dayIndexInLine) * 24 * 3600 * 1000);
    },

    getMonthLineIndexByDate: function (date) {
      let monthBetween = TimeUtils.monthsBetween(TimeUtils.getYearStart(this.originalDate), date);
      return Math.floor(monthBetween / this.monthColumns);
    },

    getMonthLineIndexByPoint: function (x, y) {
      return Math.floor((y - this.offset) / this.monthItemHeight);
    },

    getMonthIndexInLineByPoint: function (x, y) {
      return Math.floor(x / this.monthItemWidth);
    },

    getMonthByIndex(monthLineIndex, monthIndexInLine) {
      let originalMonth = TimeUtils.getYearStart(this.originalDate);
      return TimeUtils.plusMonths(originalMonth, monthLineIndex * this.monthColumns + monthIndexInLine);
    },

    getYearLineIndexByDate: function (date) {
      let yearsBetween = TimeUtils.parseDate(date).getFullYear() - 2000;
      return Math.floor(yearsBetween / this.yearColumns);
    },

    getYearLineIndexByPoint: function (x, y) {
      return Math.floor((y - this.offset) / this.yearItemHeight);
    },

    getYearIndexInLineByPoint: function (x, y) {
      return Math.floor(x / this.yearItemWidth);
    },

    getYearByIndex: function (yearLineIndex, indexInLine) {
      return new Date(2000 + yearLineIndex * this.yearColumns + indexInLine, 0, 1);
    },

    getDateFromPoint: function (x, y) {
      switch (this.mode) {
        case Mode.MODE_DATE: {
          let weekIndex = this.getWeekIndexByPoint(x, y);
          let dayIndex = this.getDayIndexInLineByPoint(x, y);
          let date = this.getDateByIndex(weekIndex, dayIndex);
          return date;
        }
        case Mode.MODE_MONTH: {
          let monthLineIndex = this.getMonthLineIndexByPoint(x, y);
          let indexInLine = this.getMonthIndexInLineByPoint(x, y);
          let month = this.getMonthByIndex(monthLineIndex, indexInLine);
          return month;
        }
        case Mode.MODE_YEAR: {
          let yearLineIndex = this.getYearLineIndexByPoint(x, y);
          let indexInLine = this.getYearIndexInLineByPoint(x, y);
          let year = this.getYearByIndex(yearLineIndex, indexInLine);
          return year;
        }
      }

    },

    isDateInvalid: function (date) {
      if (this.startDate && this.startDate.getTime() > date.getTime()) return true;
      if (this.endDate && this.endDate.getTime() < date.getTime()) return true;
      return false;
    },

    isMonthInvalid: function (date) {
      let months = this.getReferenceMonth(date);
      if (this.startDate) {
        let startMonths = this.getReferenceMonth(this.startDate);
        if (startMonths > months) return true;
      }
      if (this.endDate) {
        let endMonths = this.getReferenceMonth(this.endDate);
        if (endMonths < months) return true;
      }
      return false;
    },

    isYearInvalid: function (date) {
      if (this.startDate && this.startDate.getFullYear() > date.getFullYear()) return true;
      if (this.endDate && this.endDate.getFullYear() < date.getFullYear()) return true;
      return false;
    },

    getReferenceMonth: function (date) {
      return date.getFullYear() * 12 + date.getMonth();
    },

    getDateMarkIndex: function (date) {
      let key = TimeUtils.format('yyyy-MM-dd', date);
      let index = this.dateMarks[key];
      return index;
    },

    render: function () {
      if (this.requireRender) return;
      this.requireRender = true;
      this.$nextTick(() => {
        if (this.requireRender) {
          this.requireRender = false;
          this.drawDirectly(this.ctx);
        }
      });
    },

    drawDirectly: function (ctx) {
      ctx.clearRect(0, 0, this.width, this.height);

      ctx.globalAlpha = this.getGlobalAlpha();

      switch (this.mode) {
        case Mode.MODE_DATE:
          this.drawDateMode(ctx);
          break;
        case Mode.MODE_MONTH:
          this.drawMonthMode(ctx);
          break;
        case Mode.MODE_YEAR:
          this.drawYearMode(ctx);
          break;
      }
    },

    drawDateMode: function (ctx) {
      this.drawTitle(ctx);

      ctx.save();
      ctx.beginPath();
      ctx.rect(0, this.titleHeight, this.width, this.height - this.titleHeight);
      ctx.clip()
      ctx.closePath();
      let weekIndexStart = this.getWeekIndexByPoint(0, 0);
      let weekIndexEnd = weekIndexStart + 6;
      for (let weekIndex = weekIndexStart; weekIndex <= weekIndexEnd; weekIndex++) {
        this.drawWeekLine(ctx, TimeUtils.getWeekStart(this.originalDate.getTime() + weekIndex * 7 * 24 * 3600 * 1000));
      }
      ctx.restore();
    },

    drawTitle: function (ctx) {
      for (let n = 0; n < 7; n++) {
        let x = n * this.dateItemWidth + .5 * this.dateItemWidth;
        let y = .5 * this.titleHeight;
        ctx.fillStyle = '#1d1d1f';
        ctx.font = `${this.transformSize(12)}pt Arial`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle"
        ctx.fillText(weeks[n], this.transformX(x), this.transformY(y - this.offset));
      }
    },

    drawWeekLine: function (ctx, date) {
      let weekIndex = this.getWeekIndexByDate(date);
      ctx.fillStyle = this.primaryTextColor;
      ctx.font = `${this.transformSize(14)}pt Arial}`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.lineHeight = `${this.transformSize(14)}pt`

      let previousDate = new Date(date.getTime() - 24 * 3600 * 1000);
      let nextDate = new Date(date.getTime() + 24 * 3600 * 1000);
      for (let n = 0; n < 7; n++) {
        let left = n * this.dateItemWidth;
        let top = this.titleHeight + weekIndex * this.dateItemHeight;
        let right = left + this.dateItemWidth;
        let bottom = top + this.dateItemHeight;

        let cx = .5 * (left + right);
        let cy = .5 * (top + bottom);

        let radius = .5 * Math.min(this.dateItemWidth, this.dateItemHeight);
        ctx.save();

        let isCurrentMonth = date.getFullYear() === this.currentMonth.getFullYear() && date.getMonth() === this.currentMonth.getMonth();
        if (!isCurrentMonth) {
          ctx.fillStyle = this.secondaryTextColor;
        }

        let isActived = date.getFullYear() === this.activeDate.getFullYear() && date.getMonth() === this.activeDate.getMonth() && date.getDate() === this.activeDate.getDate();
        let isHover = this.hoverDate && date.getFullYear() === this.hoverDate.getFullYear() && date.getMonth() === this.hoverDate.getMonth() && date.getDate() === this.hoverDate.getDate();

        let isInvalid = this.isDateInvalid(date);
        if (isInvalid) {
          ctx.save();
          ctx.beginPath();
          ctx.fillStyle = '#f1f1f1';
          ctx.rect(this.transformX(left), this.transformY(top), this.transformSize(this.dateItemWidth), this.transformSize(this.dateItemHeight));
          ctx.fill();
          ctx.closePath();
          ctx.restore();
        }

        let dateMarkIndex = this.getDateMarkIndex(date);
        let dateMark = null;
        let dateMarkStart = false;
        let dateMarkEnd = false;
        if (typeof dateMarkIndex !== 'undefined') {
          dateMark = this.dateMarkDefinitions[dateMarkIndex];

          let previousDateMarkIndex = this.getDateMarkIndex(previousDate);
          let nextDateMarkIndex = this.getDateMarkIndex(nextDate);

          dateMarkStart = dateMarkIndex !== previousDateMarkIndex;
          dateMarkEnd = dateMarkIndex !== nextDateMarkIndex;

        }

        if (dateMark) {

          ctx.save();
          ctx.fillStyle = dateMark.backgroundColor;
          ctx.beginPath();
          ctx.arc(this.transformSize(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
          if (!dateMarkStart && !dateMarkEnd) {
            ctx.rect(this.transformX(left), this.transformY(top), this.transformSize(this.dateItemWidth), this.transformSize(this.dateItemHeight));
          } else if (!dateMarkStart) {
            ctx.rect(this.transformX(left), this.transformY(top), this.transformSize(.5 * this.dateItemWidth), this.transformSize(this.dateItemHeight));
          } else if (!dateMarkEnd) {
            ctx.rect(this.transformX(cx), this.transformY(top), this.transformSize(.5 * this.dateItemWidth), this.transformSize(this.dateItemHeight));
          }
          ctx.fill();
          ctx.closePath();
          ctx.restore();
        }

        if (isActived) {
          ctx.fillStyle = this.activedColor;
          ctx.beginPath();
          ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
          ctx.fill();
          ctx.closePath();
          ctx.fillStyle = 'white';
        } else if (isHover) {
          ctx.save();
          ctx.fillStyle = "#f1f1f1";
          ctx.beginPath();
          ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
          ctx.fill();
          ctx.closePath();
          ctx.restore();
        }

        ctx.fillText(TimeUtils.format('dd', date), this.transformX(cx), this.transformY(cy));

        if (isHover || date.getDate() === 1) {
          ctx.font = `${this.transformSize(8)}pt Arial`
          ctx.fillText(TimeUtils.format('MM月', date), this.transformX(cx), this.transformY(cy) + this.transformSize(16));
        }
        ctx.restore();

        previousDate = date;
        date = nextDate;
        nextDate = new Date(nextDate.getTime() + 24 * 3600 * 1000);
      }
    },

    drawMonthMode: function (ctx) {
      let lineIndexStart = this.getMonthLineIndexByPoint(0, 0);
      let lineIndexEnd = lineIndexStart + this.monthRows + 1;
      for (let lineIndex = lineIndexStart; lineIndex <= lineIndexEnd; lineIndex++) {
        for (let indexInLine = 0; indexInLine < this.monthColumns; indexInLine++) {
          let month = this.getMonthByIndex(lineIndex, indexInLine);

          let left = indexInLine * this.monthItemWidth;
          let top = lineIndex * this.monthItemHeight;
          let right = left + this.monthItemWidth;
          let bottom = top + this.monthItemHeight;

          let cx = .5 * (left + right);
          let cy = .5 * (top + bottom);
          let radius = .5 * Math.min(this.monthItemWidth, this.monthItemHeight);

          let isCurrentYear = month.getFullYear() === this.currentYear.getFullYear();
          let isActiveMonth = month.getFullYear() === this.currentMonth.getFullYear() && month.getMonth() === this.currentMonth.getMonth();
          let isHover = this.hoverDate && month.getFullYear() === this.hoverDate.getFullYear() && month.getMonth() === this.hoverDate.getMonth();

          let isInvalid = this.isMonthInvalid(month);

          ctx.save();
          ctx.fillStyle = isCurrentYear ? this.primaryTextColor : this.secondaryTextColor;
          ctx.font = `${this.transformSize(14)}pt Arial`
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          if (isInvalid) {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = '#f1f1f1';
            ctx.rect(this.transformX(left), this.transformY(top), this.transformSize(this.monthItemWidth), this.transformSize(this.monthItemHeight));
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }

          if (isActiveMonth) {
            ctx.fillStyle = this.activedColor;
            ctx.beginPath();
            ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
            ctx.fillStyle = "white";
          } else if (isHover) {
            ctx.save();
            ctx.fillStyle = "#f1f1f1";
            ctx.beginPath();
            ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }

          ctx.fillText(TimeUtils.format('MM月', month), this.transformX(cx), this.transformY(cy));

          if (isHover || month.getMonth() === 0) {
            ctx.font = `${this.transformSize(8)}pt Arial`;
            ctx.fillText(TimeUtils.format('yyyy年', month), this.transformX(cx), this.transformY(cy) + this.transformSize(18));
          }
          ctx.restore();
        }
      }

    },

    drawYearMode: function (ctx) {
      let lineIndexStart = this.getYearLineIndexByPoint(0, 0);
      let lineIndexEnd = this.getYearLineIndexByPoint(0, this.height);
      for (let lineIndex = lineIndexStart; lineIndex <= lineIndexEnd; lineIndex++) {
        for (let indexInLine = 0; indexInLine < this.yearColumns; indexInLine++) {
          let year = this.getYearByIndex(lineIndex, indexInLine);

          let left = indexInLine * this.yearItemWidth;
          let top = lineIndex * this.yearItemHeight;
          let right = left + this.yearItemWidth;
          let bottom = top + this.yearItemHeight;

          let cx = .5 * (left + right);
          let cy = .5 * (top + bottom);

          let radius = .5 * Math.min(this.yearItemWidth, this.yearItemHeight);

          let isActive = this.currentYear.getFullYear() === year.getFullYear();
          let isHover = this.hoverDate && year.getFullYear() === this.hoverDate.getFullYear();

          let isInvalid = this.isYearInvalid(year);

          ctx.save();
          ctx.fillStyle = this.primaryTextColor;
          ctx.font = `${this.transformSize(14)}pt Arial`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          if (isInvalid) {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = '#f1f1f1';
            ctx.rect(this.transformX(left), this.transformY(top), this.transformSize(this.yearItemWidth), this.transformSize(this.yearItemHeight));
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }

          if (isActive) {
            ctx.save();
            ctx.fillStyle = this.activedColor;
            ctx.beginPath();
            ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
            ctx.restore();
            ctx.fillStyle = "white";
          } else if (isHover) {
            ctx.save();
            ctx.fillStyle = "#f1f1f1";
            ctx.beginPath();
            ctx.arc(this.transformX(cx), this.transformY(cy), this.transformSize(radius), 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }

          ctx.fillText(TimeUtils.format('yyyy年', year), this.transformX(cx), this.transformY(cy));
          ctx.restore();
        }
      }
    }


  },
}
</script>

<style scoped>

.calendar {
  user-select: none;
}

.btn-expend {
  background-color: #f1f1f1;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 16px;
  text-align: center;
  color: #bec0c0;
}

.btn-title {
  border-radius: 4px;
  cursor: pointer;
}

.btn-title:hover {
  background-color: #f1f1f1;
  user-select: none;
}

.title-icon {
  background-color: #f1f1f1;
  border-radius: 50%;
  padding: 4px;
  width: 16px;
  height: 16px;

}

.calendar-container {
  overflow: hidden;
}

</style>